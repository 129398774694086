import * as React from "react"
import Layout from "../components/layout"
import "../styles/style.css"
import * as profile from '../styles/myprofile.module.scss'
import { graphql,useStaticQuery } from "gatsby"
import {GatsbyImage} from 'gatsby-plugin-image'
import logo from '../images/logo.jpg'
import awsConfig from '../util/scoop-Setting'
import isLocalhost from "../util/localHost"
import Auth from "@aws-amplify/auth"
import axios from "axios"

// markup
const [
    localRedirectSignIn,
    productionRedirectSignIn,
  ] = awsConfig.oauth.redirectSignIn.split(",");
  
  const [
    localRedirectURI,
    productionRedirectURI,
  ] = awsConfig.oauth.redirectURI.split(",");
  
  
  const [
    localRedirectSignOut,
    productionRedirectSignOut,
  ] = awsConfig.oauth.redirectSignOut.split(",");
  
  const updatedAwsConfig = {
    ...awsConfig,
    oauth: {
      ...awsConfig.oauth,
      redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
      redirectURI: isLocalhost ? localRedirectURI : productionRedirectURI,
      redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
    }
  }
  
  Auth.configure(updatedAwsConfig);
  ;
function GetPost(){
  const data =''
  return data
}
const  News= () => {
    
    const data = GetPost()
    
    console.log(data)
    return (



            <div className = "contents">
              <div className ="header-news">
                <p>Our</p>
                <h1>Instagram Feeds</h1>  
              </div>
                <div style={{width:'50vw',margin:'auto auto'}}>
                
                <div id="post" className={profile.content}>
                {/*data.map(
                    item=>(
                        <div className={'instaPost'}>
                            <a href="href='https://www.instagram.com/scoop__and__smile/">
                                <div style={{display:"flex",margin:5}}>
                                    
                                        <img style={{margin:0,borderRadius:'100%'}} height='35px' src={logo}/>
                                        <p style={{margin:0,marginLeft:5}}>Scoop__and__Smile</p>
                                </div>
                            </a>

                            <a href={item.node.permalink}>
                                <img key ={item.node.id}src ={item.node.localImage.url}></img>
                            
                                <h4 style={{color:'black',textDecoration:'none',whiteSpace: 'pre-line'}}>{item.node.caption}</h4>
                            </a>
                        </div>
                      )
                    )*/}
                
                </div>
                
                
            </div>
            </div>


    )
}


export default News
